<template>
    <div>
        <b-form-input
            ref="currency_input"
            :type="isInputActive ? 'number' : 'text'"
            v-model="displayValue"
            :state="state"
            @blur="isInputActive = false"
            @focus="
                ($evt) => {
                    $emit('focus', $evt)
                    isInputActive = true
                }
            "
            :disabled="disabled"
        />
    </div>
</template>
<script>
const currencyFormat = (value) => {
    // console.log(value, 'a');
    const newVal = parseFloat(value)
    // console.log(newVal, 'b');
    return `$ ${newVal
        .toString()
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1.')}`
    //
}

export default {
    name: 'currency-input',
    data: () => ({
        isInputActive: false
    }),
    props: ['value', 'state', 'disabled', 'focus'],
    // methods: {
    //   input (val) {
    //     console.log(this.$refs.currency_input)
    //     // console.log(val);
    //     return val.replace(/\D/g, "")
    //   }
    // },
    computed: {
        displayValue: {
            get: function () {
                // console.log(this.value)
                if (this.isInputActive) {
                    // console.log(this.value);
                    // let value = this.value ? this.value : '0'
                    const value =
                        typeof this.value === 'string'
                            ? parseFloat(this.value)
                            : this.value
                    // return `$ ${value}`.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.");
                    // Cursor is inside the input field. unformat display value for user
                    // console.log(value);
                    return value.toString()
                } else {
                    // User is not modifying now. Format display value for user interface
                    return currencyFormat(this.value ? this.value : '0')
                }
            },
            set: function (modifiedValue) {
                // console.log(modifiedValue);
                // Recalculate value after ignoring "$" and "," in user input
                // let newValue = parseFloat(modifiedValue)
                // .replace(/[^\d]/g, "")
                // Ensure that it is not NaN
                // if (isNaN(newValue)) {
                //   newValue = 0
                // }
                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it
                this.$emit('input', modifiedValue)
            }
        }
    }
}
</script>
